.star-shooting-section {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    animation: animateBg 50s linear infinite;
}

@keyframes animateBg {

    0%,
    100% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
    }
}

.star-shooting {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 4px;
    height: 4px;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.1), 0 0 0 8px rgba(255, 255, 255, 0.1), 0 0 20px rgba(255, 255, 255, 1);
    animation: animate 3s linear infinite;
}

.star-shooting::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 300px;
    height: 1px;
    background: linear-gradient(90deg, #fff, transparent);
}

@keyframes animate {
    0% {
        transform: rotate(315deg) translateX(0);
        opacity: 1;
    }

    70% {
        opacity: 1;
    }

    100% {
        transform: rotate(315deg) translateX(-400px);
        opacity: 0;
    }
}

.star-shooting:nth-child(1) {
    top: 0;
    right: 0;
    left: initial;
    animation-delay: 0;
    animation-duration: 1s;
}

.star-shooting:nth-child(2) {
    top: 0;
    right: 80px;
    left: initial;
    animation-delay: 0.2s;
    animation-duration: 3s;
}

.star-shooting:nth-child(3) {
    top: 80px;
    right: 0px;
    left: initial;
    animation-delay: 0.4s;
    animation-duration: 2s;
}

.star-shooting:nth-child(4) {
    top: 0;
    right: 180px;
    left: initial;
    animation-delay: 0.6s;
    animation-duration: 1.5s;
}

.star-shooting:nth-child(5) {
    top: 0;
    right: 400px;
    left: initial;
    animation-delay: 0.8s;
    animation-duration: 2.5s;
}

.star-shooting:nth-child(6) {
    top: 0;
    right: 1500px;
    left: initial;
    animation-delay: 1s;
    animation-duration: 3s;
}

.star-shooting:nth-child(7) {
    top: 0px;
    left: 1000px;
    animation-delay: 1s;
    animation-duration: 1.75s;
}

.star-shooting:nth-child(8) {
    top: 0px;
    right: 700px;
    left: initial;
    animation-delay: 1.4s;
    animation-duration: 1.25s;
}

.star-shooting:nth-child(9) {
    top: 0px;
    right: 1200px;
    left: initial;
    animation-delay: 0.75s;
    animation-duration: 2.25s;
}

.star-shooting:nth-child(10) {
    top: 0px;
    right: 1000px;
    left: initial;
    animation-delay: 2.75s;
    animation-duration: 2.25s;
}


.bird {
    background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/174479/bird-cells-new.svg');
    background-size: auto 100%;
    width: 88px;
    height: 125px;
    will-change: background-position;
    -webkit-animation-name: fly-cycle;
    animation-name: fly-cycle;
    -webkit-animation-timing-function: steps(10);
    animation-timing-function: steps(10);
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    z-index: 5;
}

.bird--one {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
}

.bird--two {
    -webkit-animation-duration: 0.9s;
    animation-duration: 0.9s;
    -webkit-animation-delay: -0.75s;
    animation-delay: -0.75s;
}

.bird--three {
    -webkit-animation-duration: 1.25s;
    animation-duration: 1.25s;
    -webkit-animation-delay: -0.25s;
    animation-delay: -0.25s;
}

.bird--four {
    -webkit-animation-duration: 1.1s;
    animation-duration: 1.1s;
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
}


.bird-container {
    position: absolute;
    top: 0%;
    left: -7.5vw;
    -webkit-transform: scale(0);
    transform: scale(0);
    will-change: transform;
    -webkit-animation-name: fly-right-one;
    animation-name: fly-right-one;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

.bird-container--one {
    -webkit-animation-duration: 15s;
    animation-duration: 15s;
    -webkit-animation-delay: 0;
    animation-delay: 0;
}

.bird-container--two {
    -webkit-animation-duration: 16s;
    animation-duration: 16s;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}

.bird-container--three {
    -webkit-animation-duration: 14.6s;
    animation-duration: 14.6s;
    -webkit-animation-delay: 9.5s;
    animation-delay: 9.5s;
}

.bird-container--four {
    -webkit-animation-duration: 16s;
    animation-duration: 16s;
    -webkit-animation-delay: 10.25s;
    animation-delay: 10.25s;
}

@-webkit-keyframes fly-cycle {
    100% {
        background-position: -900px 0;
    }
}

@keyframes fly-cycle {
    100% {
        background-position: -900px 0;
    }
}

@-webkit-keyframes fly-right-one {
    0% {
        left: -10%;
        -webkit-transform: scale(0.3);
        transform: scale(0.3);
    }

    10% {
        left: 10%;
        -webkit-transform: translateY(2vh) scale(0.4);
        transform: translateY(2vh) scale(0.4);
    }

    20% {
        left: 30%;
        -webkit-transform: translateY(0vh) scale(0.5);
        transform: translateY(0vh) scale(0.5);
    }

    30% {
        left: 50%;
        -webkit-transform: translateY(4vh) scale(0.6);
        transform: translateY(4vh) scale(0.6);
    }

    40% {
        left: 70%;
        -webkit-transform: translateY(2vh) scale(0.6);
        transform: translateY(2vh) scale(0.6);
    }

    50% {
        left: 90%;
        -webkit-transform: translateY(0vh) scale(0.6);
        transform: translateY(0vh) scale(0.6);
    }

    60% {
        left: 110%;
        -webkit-transform: translateY(0vh) scale(0.6);
        transform: translateY(0vh) scale(0.6);
    }

    100% {
        left: 110%;
        opacity: 1;
        -webkit-transform: translateY(0vh) scale(0.6);
        transform: translateY(0vh) scale(0.6);
    }
}

@keyframes fly-right-one {
    0% {
        left: -10%;
        -webkit-transform: scale(0.3);
        transform: scale(0.3);
    }

    10% {
        left: 10%;
        -webkit-transform: translateY(2vh) scale(0.4);
        transform: translateY(2vh) scale(0.4);
    }

    20% {
        left: 30%;
        -webkit-transform: translateY(0vh) scale(0.5);
        transform: translateY(0vh) scale(0.5);
    }

    30% {
        left: 50%;
        -webkit-transform: translateY(4vh) scale(0.6);
        transform: translateY(4vh) scale(0.6);
    }

    40% {
        left: 70%;
        -webkit-transform: translateY(2vh) scale(0.6);
        transform: translateY(2vh) scale(0.6);
    }

    50% {
        left: 90%;
        -webkit-transform: translateY(0vh) scale(0.6);
        transform: translateY(0vh) scale(0.6);
    }

    60% {
        left: 110%;
        -webkit-transform: translateY(0vh) scale(0.6);
        transform: translateY(0vh) scale(0.6);
    }

    100% {
        left: 110%;
        opacity: 1;
        -webkit-transform: translateY(0vh) scale(0.6);
        transform: translateY(0vh) scale(0.6);
    }
}

@-webkit-keyframes fly-right-two {
    0% {
        left: -10%;
        opacity: 1;
        -webkit-transform: translateY(-2vh) scale(0.5);
        transform: translateY(-2vh) scale(0.5);
    }

    10% {
        left: 10%;
        -webkit-transform: translateY(0vh) scale(0.4);
        transform: translateY(0vh) scale(0.4);
    }

    20% {
        left: 30%;
        -webkit-transform: translateY(-4vh) scale(0.6);
        transform: translateY(-4vh) scale(0.6);
    }

    30% {
        left: 50%;
        -webkit-transform: translateY(1vh) scale(0.45);
        transform: translateY(1vh) scale(0.45);
    }

    40% {
        left: 70%;
        -webkit-transform: translateY(-2.5vh) scale(0.5);
        transform: translateY(-2.5vh) scale(0.5);
    }

    50% {
        left: 90%;
        -webkit-transform: translateY(0vh) scale(0.45);
        transform: translateY(0vh) scale(0.45);
    }

    51% {
        left: 110%;
        -webkit-transform: translateY(0vh) scale(0.45);
        transform: translateY(0vh) scale(0.45);
    }

    100% {
        left: 110%;
        -webkit-transform: translateY(0vh) scale(0.45);
        transform: translateY(0vh) scale(0.45);
    }
}

@keyframes fly-right-two {
    0% {
        left: -10%;
        opacity: 1;
        -webkit-transform: translateY(-2vh) scale(0.5);
        transform: translateY(-2vh) scale(0.5);
    }

    10% {
        left: 10%;
        -webkit-transform: translateY(0vh) scale(0.4);
        transform: translateY(0vh) scale(0.4);
    }

    20% {
        left: 30%;
        -webkit-transform: translateY(-4vh) scale(0.6);
        transform: translateY(-4vh) scale(0.6);
    }

    30% {
        left: 50%;
        -webkit-transform: translateY(1vh) scale(0.45);
        transform: translateY(1vh) scale(0.45);
    }

    40% {
        left: 70%;
        -webkit-transform: translateY(-2.5vh) scale(0.5);
        transform: translateY(-2.5vh) scale(0.5);
    }

    50% {
        left: 90%;
        -webkit-transform: translateY(0vh) scale(0.45);
        transform: translateY(0vh) scale(0.45);
    }

    51% {
        left: 110%;
        -webkit-transform: translateY(0vh) scale(0.45);
        transform: translateY(0vh) scale(0.45);
    }

    100% {
        left: 110%;
        -webkit-transform: translateY(0vh) scale(0.45);
        transform: translateY(0vh) scale(0.45);
    }
}