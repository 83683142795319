#metro1 {
    /* background-position: top;
    height: 400px; */
    overflow-x: hidden;
}

#metro {
    margin-top: -100px;
    width: 100%;
    height: 60px;
    background-color: transparent;
    transform: translateX(0px);
    animation-name: movetrain;
    animation-duration: 8s;
    animation-iteration-count: infinite;
}

#metro span {
    display: inline-block;
}

.door,
.door2 {
    width: 30px;
    height: 43px;
    background-color: #14378A;
    position: absolute;
    margin-top: 18px;
    margin-left: 10px;
    /* box-shadow: inset 0 -5px 0 #000000; */
    border: 1px solid black;
}

.door2 {
    width: 25px;
    margin-top: -15px;
    margin-left: 215px;
}

.door::before,
.door2::before {
    content: "";
    width: 20px;
    height: 19px;
    background-color: #008ACB;
    box-shadow: inset 0 0 10px #004FC8, inset 0 2px 0 blue;
    position: absolute;
    margin-top: 4px;
    margin-left: 3px;
    border: 3px solid #961703;
}

.door2::before {
    width: 17px;
    margin-left: 3px;
}

.mirror {
    width: 154px;
    height: 15px;
    background-color: #666665;
    margin-top: 18px;
    margin-left: 50px;
    border: 1px solid black;
}

.mirror span {
    display: inline-block;
}

.m1,
.m2,
.m3,
.m4 {
    display: inline-block;
    width: 35px;
    height: 11px;
    background-color: #000000;
    position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    box-shadow: inset 0 0 8px #545658;
    border: 1px solid rgb(82, 82, 82);
}

.m1-night,
.m2-night,
.m3-night,
.m4-night {
    display: inline-block;
    width: 35px;
    height: 11px;
    background-color: rgb(239, 243, 113);
    position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    box-shadow: inset 0 0 8px #545658;
    border: 1px solid rgb(82, 82, 82);
}

.m2-night {
    margin-left: 40px;
}

.m3-night {
    margin-left: 79px;
}

.m4-night {
    margin-left: 117px;
}

.m2 {
    margin-left: 40px;
}

.m3 {
    margin-left: 79px;
}

.m4 {
    margin-left: 117px;
}

.back {
    background-color: #E3E1E1;
    width: 250px;
    height: 60px;
    border-radius: 10px 10px 10px 10px;
    box-shadow: inset 0 4px 0 #ffffff, inset 0 10px 0 #CF1D01;
}

.back::after {
    content: "";
    background-color: black;
    width: 6px;
    height: 10px;
    position: absolute;
    margin-left: 249px;
    margin-top: 1px;
    border-radius: 10px;
}

.back::before {
    content: "";
    width: 250px;
    height: 20px;
    background-color: #14378A;
    /* border-top: 1px solid #CF1D01; */
    box-shadow: inset 0px 4px 0px #E3E1E1, 0 4px 0px black, inset 0 -5px 0px #14378A;
    position: absolute;
    margin-top: 40px;
    border-radius: 0px 0px 10px 10px;
}



.track {
    background-color: #b2bdbf;
    width: 100%;
    height: 10px;
    border-top: 3px solid black;
    position: relative;
    margin-top: 5px;
}

.engine {
    background-color: #E3E1E1;
    width: 260px;
    height: 60px;
    border-radius: 10px 30% 10px 10px;
    box-shadow: inset 0 4px 0 #ffffff, inset 0 10px 0 #CF1D01;
    clip-path: polygon(82% 1%, 95% 15%, 100% 36%, 100% 148%, -13% 100%, 0% 0%);
}

.engine::before {
    content: "";
    width: 260px;
    height: 20px;
    background-color: #14378A;
    /* border-top: 1px solid #CF1D01; */
    box-shadow: inset 0px 4px 0px #E3E1E1, 0 4px 0px black, inset 0 -5px 0px #14378A;
    position: absolute;
    margin-top: 40px;
    border-radius: 0 0px 10px 10px;
}

.glass {
    width: 15px;
    height: 27px;
    background-color: black;
    position: absolute;
    margin-top: -25px;
    margin-left: 250px;
    border: 1px solid black;
    border-radius: 7px;
}

.glass-night {
    width: 15px;
    height: 27px;
    background-color: rgb(239, 243, 113);
    position: absolute;
    margin-top: -25px;
    margin-left: 250px;
    border: 1px solid black;
    border-radius: 7px;
}

@keyframes movetrain {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(100%);
    }

    80% {
        transform: translateX(300px);
    }

    81% {
        transform: translateX(310px);
    }

    82% {
        transform: translateX(311px);
    }

    83% {
        transform: translateX(312px);
    }

    84% {
        transform: translateX(313px);
    }

    85% {
        transform: translateX(314px);
    }

    89% {
        transform: translateX(320px);
    }

    90% {
        transform: translateX(400px);
    }
}